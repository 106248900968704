<template>
  <div>
    <div v-if="!success" class="welfare-activities">
      <div class="top">
        <img width="100%" src="@/assets/images/activity/banner.png" />
      </div>
      <div class="center">
        <div class="center-form">
          <van-field v-model.trim="form.name" placeholder="请输入姓名">
            <div class="custom-left-icon" slot="left-icon">
              <img width="20px" src="@/assets/images/activity/icon_name.svg" />
              <div class="line"></div>
            </div>
          </van-field>
          <van-field v-model.trim="form.mobile" placeholder="请输入手机号码">
            <div class="custom-left-icon" slot="left-icon">
              <img width="20px" src="@/assets/images/activity/icon_number.svg" />
              <div class="line"></div>
            </div>
          </van-field>
          <van-field v-model.trim="form.companyName" placeholder="请输入公司名称">
            <div class="custom-left-icon" slot="left-icon">
              <img width="20px" src="@/assets/images/activity/icon_company.svg" />
              <div class="line"></div>
            </div>
          </van-field>
          <van-field v-model.trim="form.email" placeholder="请输入邮箱">
            <div class="custom-left-icon" slot="left-icon">
              <img width="20px" src="@/assets/images/activity/icon_mailbox.svg" />
              <div class="line"></div>
            </div>
          </van-field>
          <van-button @click="submitForm" type="info" :disabled="receiveAble ? false : true">领取福利</van-button>
        </div>
        <div class="label-rule">
          <div class="label-rule_header">
            <div class="inline"></div>
            <div class="inline-name">活动规则</div>
            <div class="inline"></div>
          </div>
          <div class="rule-title">活动时间：</div>
          <div class="rule-content">2021.8.9-2021.8.18</div>
          <div class="rule-title">参与方式：</div>
          <div class="rule-content">
            <p>1.奖品为价值10000元的AI面试大礼包；</p>
            <p>2.工作人员审核通过后，会在24小时之内联系您，为您发放；</p>
            <p>3.福利有效期：自领取日起，一个月内有效，请在有效期内使用。不得转赠、提现，或退改；</p>
            <p>4.如有疑问，拨打客服电话：4000-963-520。</p>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>
    <div v-if="success" class="success-more">
      <img src="@/assets/images/activity/more_img.png" />
      <van-button @click="toHome" type="info">领取成功，获取更多产品内容</van-button>
      <div class="tip">*注：点此会跳转到AI得贤招聘官官方网站</div>
    </div>
  </div>
</template>
<script>
import { saveInfo } from '@/api/enterprise'
export default {
  data() {
    return {
      form: {
        email: '',
        mobile: '',
        name: '',
        companyName: '',
      },
      // {"companyName":"公司名称",
      // "mobile":"手机号码",
      // "email":"邮箱",
      // "name":"个人姓名",
      // "position":"职位"
      // }
      success: false,
    }
  },
  methods: {
    submitForm() {
      saveInfo(this.form).then(res => {
        if (res && res.code === 0) {
          this.$toast.success('领取成功')
          this.success = true
        } else {
          this.$toast(res.msg)
        }
      })
    },
    toHome() {
      this.$router.push({
        path: '/',
      })
    },
  },
  computed: {
    receiveAble() {
      return this.form.name && this.form.mobile && this.form.email && this.form.companyName
    },
  },
}
</script>
<style lang="scss" scoped>
.welfare-activities {
  height: 100vh;
  width: 100%;
  margin: auto;
  position: relative;

  // display: flex;
  // flex-direction: column;
  // background: url('../../assets/images/activity/activity.png') no-repeat;
  // background-size: contain;
  // background-position-x: center;
  // background-position: center;
  .top {
    // flex: 1;
  }
  .center {
    // flex: 1;
    position: relative;
    top: -200px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .center-form {
      width: 360px;
      padding: 35px 10px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      .custom-left-icon {
        padding-left: 15px;
        padding-right: 8px;
        display: flex;
        align-items: center;
        // border-right: 1px solid rgba(0, 0, 0, 0.2);
        img {
          margin-right: 10px;
        }
        .line {
          width: 1px;
          height: 18px;
          background: rgba(0, 0, 0, 0.2);
        }
      }
      .van-button {
        width: 280px;
        margin-top: 30px;
      }
    }
    .label-rule {
      width: 360px;
      padding: 10px 0;
      .label-rule_header {
        display: flex;
        justify-content: center;
        align-items: center;
        .inline {
          width: 110px;
          height: 2px;
          background: #ccd2d6;
        }
        .inline-name {
          font-size: 24px;
          font-weight: 500;
          color: #212121;
          margin: 0 10px;
        }
      }
      .rule-title {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #212121;
      }
      .rule-content {
        margin-top: 5px;
        font-size: 14px;

        font-weight: 400;
        color: #5e5e5e;
        line-height: 16px;
        p {
          margin: 0;
          line-height: 16px;
        }
      }
    }
  }
  .bottom {
    // flex: 1;
  }
}
.success-more {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // text-align: center;
  // display: flex;
  // flex-direction: column;
  img {
    width: 200px;
    margin-bottom: 20px;
  }
  .van-button {
    width: 300px;
    margin-bottom: 20px;
  }
  .tip {
    font-weight: 400;
    color: #212121;
    line-height: 24px;
  }
}
</style>
